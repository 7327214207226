/**
 * 审核节点
 * @param {Number} oldState 
 * @returns {String}
 */
export const auditNode = (oldState) => {
    switch (oldState) {
        case 0:
            return "采购专员";
        case 1:
            return "项目审批员";
        case 11:
            return "总部审批员";
        case 2:
            return "集采专员";
        case 3:
            return "集采经理";
        case 4:
            return "集采总监";
        case 5:
            return "出纳";
        case 6:
            return "财务";
        case 20:
            return "项目审批员";
        case 21:
            return "订单审批员";
        case 22:
            return "二级订单审批员";
        default:
            return "";
    }
}

/**
 * 审核节点状态
 * @param {Number} oldState 
 * @param {Number} newState 
 * @returns {String}
 */
export const auditNodeState = (oldState, newState) => {
	if(oldState == 0) {
        return "提交审核";
    } else if((oldState >= 1 && oldState <= 4) || oldState == 11) {
        return newState == 9 ? "已驳回" : "通过";
    } else if(oldState == 5) {
        if(newState == 9) {
            return "已驳回";
        } else if(newState == 6) {
            return "申请先发货后付款";
        } else {
            return "确认收款";
        }
    } else if(oldState == 6) {
        if(newState == 9 || newState == 5) {
            return "已驳回";
        } else {
            return "通过";
        }
    } else if(oldState >= 20) {
        if((oldState == 22 && newState == 21) || newState == 9) {
            return "已驳回";
        } else {
            return "通过";
        }
    }
}

/**
 * 订单状态
 * @param {Number} auditState 
 * @param {Number} state 
 * @returns {String}
 */
export const auditStateFun = (auditState, state) => {
	if (auditState === 1 || auditState === 20) {
        if (state == 7) {
            return "已取消";
        } else {
            return "待审核";
        }
    } else if (auditState === 11) {
        if (state == 7) {
            return "已取消";
        } else {
            return "等待总部审批";
        }
    } else if (
        auditState === 2 || 
        auditState === 3 || 
        auditState === 4 || 
        auditState >= 21 && auditState <= 25
    ) {
        if (state == 7) {
            return "已取消";
        } else {
            return "等待后台审核";
        }
    } else if ((auditState > 4 && auditState < 9) || auditState === 0) {
        switch (state) {
            case 1:
                return "待付款";
            case 2:
                return "待发货";
            case 3:
                return "待收货";
            case 4:
                return "待评价";
            case 5:
                return "已完成";
            case 6:
                return "退货退款";
            case 7:
                return "已取消";
            default:
                return "已通过";
        }
    } else if (auditState === 9) {
        return "已驳回";
    }
}

/**
 * 订单审批按钮判断
 * @param {String} btn // 按钮名称
 * @param {Number | String} auditState 
 * @param {Number | String} state 
 * @param {String | Date} payTime 
 * @returns {Boolean | String}
 */
export const auditBtnFn = (btn, auditState, state, payTime) => {
    // 订单状态
    let name = auditStateFun(auditState, state)
    if(btn == "审批"){
        if(name == "待审核" || name == "等待总部审批"){
            return true
        }
    } else if(btn == "上传汇款信息"){
        if(name == "待审核" || name == "等待总部审批" || name == "等待后台审核" || name == "待发货" || name == "待收货" || name == "待付款"){
            return true
        }
    } else if(btn == "对公转账信息"){
        if(name == "待审核" || name == "等待总部审批" || name == "等待后台审核" || name == "待发货" || name == "待收货" || name == "待付款"){
            return true
        }
    } else if(btn == "查看订单"){
        if(name == "待审核" || name == "等待总部审批" || name == "等待后台审核" || name == "已通过" || name == "待发货" || name == "待收货" || name == "待付款" || name == "已驳回" || name == "已完成" || name == "已取消"){
            return true
        }
    } else if(btn == "取消订单"){
        if(auditState < 20){
            if(name == "待审核" || name == "等待总部审批" || name == "等待后台审核" || name == "已通过"){
                return true
            } else if(name == "已完成" || name == "待收货"){
                return "jump" // 跳转（售后）
            } else if(name == "待发货"){
                return "待发货"
            } else if(name == "待付款"){
                return "待付款"
            }
        } else { // 外部企业
            if(name != "已取消"){
                if(payTime && state == 1){
                    return true
                } else if(!payTime){
                    return true
                }
            }
        }
    } else if(btn == "确认收货"){
        if(name == "待收货"){
            return true
        }
    } else if(btn == "重新加入购物车" || btn == "重新购买"){
        if(name == "已驳回" || name == "已完成" || name == "已取消"){
            return true
        }
    } else if(btn == "查看发票"){
        if(name == "已完成"){
            return true
        }
    }
    return false
}
<template>
    <div>
        <!-- 查看审批文件 -->
        <el-dialog
            :visible="dialog"
            width="40%"
            title="查看审批文件"
            center
            @close="closeDialog"
        >
            <div class="dialogVisibleBox">
                <div class="fileListBox" v-if="fileList.length > 0">
                    <div class="fileList flex-between" v-for="(item,index) in fileList" :key="index">
                        <div style="width: 68%;">审批文件{{index+1}}：{{item.fileName}}</div>
                        <div class="fileBtn" style="text-align: right;">
                            <span @click="filePreview(item)">在线预览</span>
                            <el-popconfirm
                                v-if="delBtn"
                                title="确定删除吗？"
                                @confirm="fileDel(item,index)"
                            >
                                <span slot="reference">删除</span>
                            </el-popconfirm>
                        </div>
                    </div>
                </div>
                <div class="fileListBox" v-else>
                    <el-empty description="无审批文件" :image-size="90"></el-empty>
                </div>

                <div class="hint-box">
                    <div>温馨提示：</div>
                    <div>1.最多上传10个文件；文件格式仅支持PDF、JPG、PNG，文件大小需≤10MB。</div>
                    <div>2.删除文件时，将同步删除审批账号的相关文件。当审批完成后，则无法修改s审批文件。</div>
                </div>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-upload
                    v-if="fileList.length < 10"
                    class="approveUpload-file"
                    ref="approveUpload"
                    action=""
                    accept=".jpg, .png, .pdf"
                    :multiple="true"
                    :auto-upload="true"
                    :show-file-list="false"
                    :before-upload="beforeFile"
                    :http-request="requestFile"
                >
                        <el-button size="small" type="warning">上传文件</el-button>
                </el-upload>
                <el-tooltip v-else class="item" effect="dark" content="最多上传10个文件!" placement="top">
                    <el-button size="small" type="warning">上传文件</el-button>
                </el-tooltip>
                <el-button size="small" @click="closeDialog()">关 闭</el-button>
            </span>
            
        </el-dialog>
        <!-- 审批文件、汇款凭证 预览 jpg/png/pdf -->
        <el-image-viewer
            v-if="showViewer"
            :on-close="closeShowViewer"
            :url-list="showViewerList"
            style="z-index: 3000"
        />
        <!-- <previewPDF
            v-if="showPDF"
            ref="previewPDF"
            :url="srcPDF"
            :title="titlePDF"
            @closeShowPDF="closeShowPDF"
        ></previewPDF> -->
    </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
// import previewPDF from '@/components/public/previewPDF.vue'
export default {
    components: { ElImageViewer },
    props: {
        fileList: {
            type: Array,
        },
        dialog: {
            type: Boolean,
            default: false
        },
        delBtn: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return{
            // dialog: this.dialogVisible,
            showViewer: false,
            showViewerList: [],// 图片预览
            // showPDF: false,
            // srcPDF: '',// pdf预览
            // titlePDF: '',
        }
    },
    methods:{
        // 审批文件在线预览
        filePreview(item){
            // console.log(item);
            let fileName = item.fileUrl.substring(item.fileUrl.lastIndexOf(".") + 1);
            console.log(fileName,item.fileUrl);
            if(fileName == 'pdf' || fileName == 'PDF'){
                // this.titlePDF = item.fileName
                // let i = item.fileUrl.indexOf('/order')
                // this.srcPDF = '/pdf'+item.fileUrl.slice(i)
                // this.srcPDF = item.fileUrl
                // this.srcPDF = '/pdf/order%2Fimages%2Fb5cff203883c47c1922a94b23b9468e4.pdf'
                // this.srcPDF = 'http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf'
                // this.showPDF = true
                window.open(item.fileUrl)
            } else {
                this.showViewer = true
                this.showViewerList = [item.fileUrl]
            }
            
        },
        closeShowViewer(){
            this.showViewer = false
        },
        closeShowPDF(){
            this.showPDF = false
        },
        // 审批文件删除
        fileDel(item,index){
            this.$emit('del',item,index)
        },
        // 上传前
        beforeFile(file){
            this.$emit('before',file)
        },
        // 自定义上传
        requestFile(params){
            this.$emit('request',params)
        },
        // 关闭窗口
        closeDialog(){
            this.$emit('update:dialog', false)
        },
    }
}
</script>

<style lang="scss" scoped>
.dialogVisibleBox {
    width: 100%;
    height: 320px;
    position: relative;

    .fileListBox {
        width: 100%;
        height: 225px;
        margin-top: 15px;
        margin-bottom: 15px;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width : 7px;
            height: 1px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
            background   : #808080;
        }
        &::-webkit-scrollbar-track {
            box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 7px;
            background   : #ededed;
        }

        .fileList {
            margin-bottom: 15px;

            .fileBtn {
                width: 32%;
                color: #CD9F49;

                span {
                    cursor: pointer;
                    margin: 0 10px;
                }
            }
        }
    }
    .hint-box {
        width: 100%;
    }
}
.approveUpload-file {
    display: inline-block;
    margin-right: 30px;
}

</style>